// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import { api } from '../../../../utility/constants'

// Generate Jobno
export const generateJobno = createAsyncThunk('JobCutting/generateJobno', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/production/jobcutting/generateJobCuttingNoJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params['jwtToken'] = response.data.body.token
      const response1 = await axios.post(`${api.api_url}/production/jobcutting/generateJobCuttingNo`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          JobNo: response1.data.body && response1.data.body.JobNo ? response1.data.body.JobNo : ''
        }
      } else {
        return {
          JobNo: response1.data.body ? response1.data.body.JobNo : ''
        }
      }
    } else {
      return {
        JobNo: response.data.body ? response.data.body.JobNo : ''
      }
    }
  } catch (error) {
    return {
      JobNo: ''
    }
  }
})

export const onChangeJobCuting = createAsyncThunk('JobCutting/onchangeJobCutting', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/production/jobcutting/onchangeJobCuttingJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params['jwtToken'] = response.data.body.token
      const response1 = await axios.post(`${api.api_url}/production/jobcutting/onchangeJobCutting`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          designList: response1.data.body && response1.data.body.DesignList ? response1.data.body.DesignList : [],
          SubCategoryList: response1.data.body && response1.data.body.SubCategoryList ? response1.data.body.SubCategoryList : []

        }
      } else {
        return {
          designList: response1.data.body ? response1.data.body.DesignList : [],
          SubCategoryList: response1.data.body ? response1.data.body.SubCategoryList : []
        }
      }
    } else {
      return {
        designList: response.data.body ? response.data.body.DesignList : [],
        SubCategoryList: response.data.body ? response.data.body.SubCategoryList : []
      }
    }
  } catch (error) {
    return {
      designList: [],
      SubCategoryList: []
    }
  }
})

export const getSizeListData = createAsyncThunk('JobCutting/getSizeListData', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/production/jobcutting/getSizeForJobCuttingJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params['jwtToken'] = response.data.body.token
      const response1 = await axios.post(`${api.api_url}/production/jobcutting/getSizeForJobCutting`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          SizeList: response1.data.body && response1.data.body.SizeList ? response1.data.body.SizeList : []
        }
      } else {
        return {
          SizeList: response1.data.body ? response1.data.body.SizeList : []
        }
      }
    } else {
      return {
        SizeList: response.data.body ? response.data.body.SizeList : []
      }
    }
  } catch (error) {
    return {
      SizeList: []
    }
  }
})

export const getItemListData = createAsyncThunk('JobCutting/getItemListData', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/production/jobcutting/getItemsForJobCuttingJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params['jwtToken'] = response.data.body.token
      const response1 = await axios.post(`${api.api_url}/production/jobcutting/getItemsForJobCutting`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          ListItem: response1.data.body && response1.data.body.ItemList ? response1.data.body.ItemList : []
        }
      } else {
        return {
          ListItem: response1.data.body ? response1.data.body.ItemList : []
        }
      }
    } else {
      return {
        ListItem: response.data.body ? response.data.body.ItemList : []
      }
    }
  } catch (error) {
    return {
      ListItem: []
    }
  }
})

export const getColorListData = createAsyncThunk('JobCutting/getColorListData', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/production/jobcutting/getColorsForJobCuttingJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params['jwtToken'] = response.data.body.token
      const response1 = await axios.post(`${api.api_url}/production/jobcutting/getColorsForJobCutting`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          ColorList: response1.data.body && response1.data.body.ColorList ? response1.data.body.ColorList : []
        }
      } else {
        return {
          ColorList: response1.data.body ? response1.data.body.ColorList : []
        }
      }
    } else {
      return {
        ColorList: response.data.body ? response.data.body.ColorList : []
      }
    }
  } catch (error) {
    return {
      ColorList: []
    }
  }
})
// Edit JobCutting LIST
export const editData = createAsyncThunk('JobCutting/editData', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/production/jobcutting/editJobCuttingJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params['jwtToken'] = response.data.body.token
      const response1 = await axios.post(`${api.api_url}/production/jobcutting/editJobCutting`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          editJobCuttingArray: response1.data.body && response1.data.body.editJobCuttingArray ? response1.data.body.editJobCuttingArray : []
        }
      } else {
        return {
          editJobCuttingArray: response1.data.body ? response1.data.body.editJobCuttingArray : []
        }
      }
    } else {
      return {
        editJobCuttingArray: response.data.body ? response.data.body.editJobCuttingArray : []
      }
    }
  } catch (error) {
    return {
      editJobCuttingArray: []
    }
  }
})

//saveData Api
export const saveData = createAsyncThunk('JobCutting/saveData', async params => {
  try {
    const response = await axios.post(`${api.api_url}/production/jobcutting/saveJobCuttingJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params['jwtToken'] = response.data.body.token
      const response1 = await axios.post(`${api.api_url}/production/jobcutting/saveJobCutting`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          statusFlag: response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag : 2,
          message: response1.data.body.message,
          saveJobData: response1.data.body.resultData
        }
      } else {
        return {
          statusFlag: response1.data.body ? response1.data.body.statusFlag : 2,
          message: response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later',
          saveJobData: response1.data.body ? response1.data.body.resultData : {}
        }
      }
    } else {
      return {
        statusFlag: response.data.body ? response.data.body.statusFlag : 2,
        message: response.data.body ? response.data.body.message : 'Server not reachable.Please try again later',
        saveJobData: response.data.body ? response.data.body.resultData : {}
      }
    }
  } catch (error) {
    return {
      statusFlag: 2,
      message: 'Server not reachable.Please try again later',
      saveJobData: {}
    }
  }
})

export const JobCuttinglist = createAsyncThunk('JobCutting/getJobCuttinglist', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/production/jobcutting/jobcuttingListJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params['jwtToken'] = response.data.body.token
      const response1 = await axios.post(`${api.api_url}/production/jobcutting/jobcuttingList`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          JobCuttinglist: response1.data.body && response1.data.body.JobCuttingArray ? response1.data.body.JobCuttingArray : [],
          CompanyList: response1.data.body && response1.data.body.CompanyArray ? response1.data.body.CompanyArray : [],
          TotalJobCutting: response1.data.body && response1.data.body.TotalData ? response1.data.body.TotalData : []
        }
      } else {
        return {
          JobCuttinglist: response1.data.body ? response1.data.body.JobCuttingArray : [],
          CompanyList: response1.data.body ? response1.data.body.CompanyArray : [],
          TotalJobCutting: response1.data.body ? response1.data.body.TotalData : []
        }
      }
    } else {
      return {
        JobCuttinglist: response.data.body ? response.data.body.JobCuttingArray : [],
        CompanyList: response.data.body ? response.data.body.CompanyArray : [],
        TotalJobCutting: response.data.body ? response.data.body.TotalData : []
      }
    }
  } catch (error) {
    return {
      JobCuttinglist: [],
      CompanyList: [],
      TotalJobCutting: []
    }
  }
})

//DELETE JOB CUTTING
export const deleteData = createAsyncThunk('JobCutting/deleteData', async params => {
  try {
    const response = await axios.post(`${api.api_url}/production/jobcutting/deleteJobCuttingJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params['jwtToken'] = response.data.body.token
      const response1 = await axios.post(`${api.api_url}/production/jobcutting/deleteJobCutting`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          statusFlag: response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag : 2,
          message: response1.data.body.message
        }
      } else {
        return {
          statusFlag: response1.data.body ? response1.data.body.statusFlag : 2,
          message: response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
        }
      }
    } else {
      return {
        statusFlag: response.data.body ? response.data.body.statusFlag : 2,
        message: response.data.body ? response.data.body.message : 'Server not reachable.Please try again later'
      }
    }
  } catch (error) {
    return {
      statusFlag: 2,
      message: 'Server not reachable.Please try again later'
    }
  }
})

export const printJobCuttingData = createAsyncThunk('JobCutting/printJobCuttingData', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/production/jobcutting/printJobCuttingJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params['jwtToken'] = response.data.body.token
      const response1 = await axios.post(`${api.api_url}/production/jobcutting/printJobCutting`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          Print_JobCutting_Array: response1.data.body && response1.data.body.PrintJobCuttingArray ? response1.data.body.PrintJobCuttingArray : [],
          Print_Company_Array: response1.data.body && response1.data.body.PrintCompanyArray ? response1.data.body.PrintCompanyArray : [],
          print_result: 'true'
        }
      } else {
        return {
          Print_JobCutting_Array: response1.data.body ? response1.data.body.PrintJobCuttingArray : [],
          Print_Company_Array: response1.data.body ? response1.data.body.PrintCompanyArray : [],
          print_result: 'false'
        }
      }
    } else {
      return {
        Print_JobCutting_Array: response.data.body ? response.data.body.PrintJobCuttingArray : [],
        Print_Company_Array: response.data.body ? response.data.body.PrintCompanyArray : [],
        print_result: 'false'
      }
    }
  } catch (error) {
    return {
      Print_JobCutting_Array: [],
      Print_Company_Array: [],
      print_result: 'false'
    }
  }
})

//  GET Common LIST
export const commonData = createAsyncThunk('JobCutting/commonData', async (params) => {
  const response = await axios.post(`${api.api_url}/production/jobcutting/fetchFilterDataJwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params['jwtToken'] = response.data.body.token
    const response1 = await axios.post(`${api.api_url}/production/jobcutting/fetchFilterData`, params)
    if (response1 && response1.data && response1.data.body) {
      return {
        params,
        EmployeeArray: response1.data.body.EmployeeArray,
        ItemArray: response1.data.body.ItemArray,     
        allcolorArray: response1.data.body.allcolorArray,
        allitemgroupArray: response1.data.body.allitemgroupArray,
        alldesignArray: response1.data.body.alldesignArray
      }
    } else {
    }
  }
})

// Machine Wise Employee search
export const onchangemachineemployeeData = createAsyncThunk('JobCutting/onchangemachineemployeeData', async params => {
  const response = await axios.post(`${api.api_url}/common/onchangeMachineEmployeeDataJwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = { jwtToken: response.data.body.token }
    const response1 = await axios.post(`${api.api_url}/common/onchangeMachineEmployeeData`, params)
    if (response1 && response1.data && response1.data.body) {
      return {
        MachineEmployeeList: response1.data.body.MachineEmployeeList
      }
    } else {
    }
  }
}
)

// Machine search
export const onchangemachineData = createAsyncThunk('JobCutting/onchangemachineData', async params => {
  const response = await axios.post(`${api.api_url}/common/onchangeMachineDataJwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = { jwtToken: response.data.body.token }
    const response1 = await axios.post(`${api.api_url}/common/onchangeMachineData`, params)
    if (response1 && response1.data && response1.data.body) {
      return {
        ListMachine: response1.data.body.MachineList
      }
    } else {
    }
  }
}
)

export const JobCuttingSlice = createSlice({
  name: 'JobCutting',
  initialState: {
    data: [],
    total: 1,
    params: {},
    designList: [],
    SubCategoryList: [],
    JobNo: '',
    SizeList: [],
    ListItem: [],
    ColorList: [],
    JobCuttinglist: [],
    editJobCuttingArray: [],
    allData: [],
    CompanyList: [],
    TotalJobCutting: {},
    statuslist: [],
    statusFlag: 0,
    saveJobData: {},
    statuscode: 0,    
    EmployeeArray: [],
    ItemArray: [],
    ListDesign: [],
    ListMachine: [],
    ListEmployee: [],
    allcolorArray: [],
    allitemgroupArray: [],
    alldesignArray: [],
    MachineEmployeeList: [],
    jobId: ''
  },
  reducers: {
    handleStatusFlag: (state, action) => {
      state.statusFlag = action.payload.statusFlag
    },
    clearstoreData: (state, action) => {
      state.JobNo = action.payload.JobNo
      state.designList = action.payload.designList
      state.SubCategoryList = action.payload.SubCategoryList
      state.SizeList = action.payload.SizeList
      state.ListItem = action.payload.ListItem
      state.ColorList = action.payload.ColorList
      state.editJobCuttingArray = action.payload.editJobCuttingArray
      state.Print_JobCutting_Array = action.payload.Print_JobCutting_Array
      state.Print_Company_Array = action.payload.Print_Company_Array
      state.print_result = action.payload.print_result     
      state.EmployeeArray = action.payload.EmployeeArray
      state.ListSize = action.payload.ListSize
      state.MachineEmployeeList = action.payload.MachineEmployeeList
      state.ListMachine = action.payload.ListMachine

    },
    handlepage: (state, action) => {
      state.jobId = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(generateJobno.fulfilled, (state, action) => {
      state.JobNo = action.payload.JobNo
    })
      .addCase(onChangeJobCuting.fulfilled, (state, action) => {
        state.designList = action.payload.designList
        state.SubCategoryList = action.payload.SubCategoryList
      })
      .addCase(getSizeListData.fulfilled, (state, action) => {
        state.SizeList = action.payload.SizeList
      })
      .addCase(getItemListData.fulfilled, (state, action) => {
        state.ListItem = action.payload.ListItem
      })
      .addCase(getColorListData.fulfilled, (state, action) => {
        state.ColorList = action.payload.ColorList
      })
      .addCase(saveData.fulfilled, (state, action) => {
        state.statusFlag = action.payload.statusFlag
        state.message = action.payload.message
        state.saveJobData = action.payload.saveJobData
      })
      .addCase(JobCuttinglist.fulfilled, (state, action) => {
        state.JobCuttinglist = action.payload.JobCuttinglist
        state.CompanyList = action.payload.CompanyList
        state.TotalJobCutting = action.payload.TotalJobCutting
        state.params = action.payload.params
      })
      .addCase(editData.fulfilled, (state, action) => {
        state.editJobCuttingArray = action.payload.editJobCuttingArray
        state.params = action.payload.params
      })
      .addCase(deleteData.fulfilled, (state, action) => {
        state.message = action.payload.message
        state.statusFlag = action.payload.statusFlag
      })
      .addCase(printJobCuttingData.fulfilled, (state, action) => {
        state.Print_JobCutting_Array = action.payload.Print_JobCutting_Array
        state.Print_Company_Array = action.payload.Print_Company_Array
        state.print_result = action.payload.print_result
      })

      .addCase(commonData.fulfilled, (state, action) => {       
        state.EmployeeArray = action.payload.EmployeeArray
        state.ItemArray = action.payload.ItemArray
        state.allcolorArray = action.payload.allcolorArray
        state.allitemgroupArray = action.payload.allitemgroupArray
        state.alldesignArray = action.payload.alldesignArray
        state.params = action.payload.params
      })
      .addCase(onchangemachineemployeeData.fulfilled, (state, action) => {
        state.MachineEmployeeList = action.payload.MachineEmployeeList
      })
      .addCase(onchangemachineData.fulfilled, (state, action) => {
        state.ListMachine = action.payload.ListMachine
      })
  }
})
export const {
  handleStatusFlag, clearstoreData, handlepage
} = JobCuttingSlice.actions
export default JobCuttingSlice.reducer
