// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import { api } from '../../../../utility/constants'


// Save boxing data 
export const SaveBoxingData = createAsyncThunk('BoxingDataList/moveToFG', async (params) => {
  try {
  const response = await axios.post(`${api.api_url}/dispatch/moveToFGjwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params['jwtToken'] = response.data.body.token
    const response1 = await axios.post(`${api.api_url}/dispatch/moveToFG`, params)
    if (response1 && response1.data && response1.data.body) {
     return {
       statusFlag:response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag : 2,
       message:response1.data.body.message,
       saveFGData: response1.data.body.resultData
     }
    } else {
      return {
        statusFlag:response1.data.body ? response1.data.body.statusFlag : 2,
        message:response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later',
       saveFGData: response1.data.body ? response1.data.body.resultData : []
      }
     }
   } else {
     return {
      statusFlag:response.data.body ? response.data.body.statusFlag : 2,
      message:response.data.body ? response.data.body.message : 'Server not reachable.Please try again later',
      saveFGData: []
     }
    }
  } catch (error) {
    return {
      statusFlag:2,
      message:'Server not reachable.Please try again later',
      saveFGData: []
    }    
  }
})

// Item list search
export const onchangeItemList = createAsyncThunk('BoxingDataList/itemList', async params => {
  const response = await axios.post(`${api.api_url}/dispatch/itemListjwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = {jwtToken:response.data.body.token}
    const response1 = await axios.post(`${api.api_url}/dispatch/itemList`, params)
    if (response1 && response1.data && response1.data.body) {
      return {
        ItemList: response1.data.body.itemArray,
        IronMachineList:response1.data.body.ironmachineArray
      }
    } else {
    }
  }
})

// Save boxing data 
export const GetFGJobIdList = createAsyncThunk('BoxingDataList/GetFGJobIdList', async (params) => {
  const response = await axios.post(`${api.api_url}/dispatch/getFGJobIdListjwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params['jwtToken'] = response.data.body.token
    const response1 = await axios.post(`${api.api_url}/dispatch/getFGJobIdList`, params)
    if (response1 && response1.data && response1.data.body) {
     return {
      JobFGList:response1.data.body.JobFGList ? response1.data.body.JobFGList : response1.data.body
     }
    } 
   } else {
    
    }
})
//Check FG qty excess Dispatch qty
//Check FG qty excess Dispatch qty
export const checkFGDispatch = createAsyncThunk('FinishedGoodList/checkFGDispatch', async (params) => {
  try {
  const response = await axios.post(`${api.api_url}/dispatch/checkFGItemDispatchJwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params['jwtToken'] = response.data.body.token
    const response1 = await axios.post(`${api.api_url}/dispatch/checkFGItemDispatch`, params)
    if (response1 && response1.data && response1.data.body) { 
    return { 
      Check_FGlist: response1.data.body && response1.data.body.FGArray  ? response1.data.body.FGArray : [],
      Check_FGmessage: response1.data.body && response1.data.body.FGArray ? response1.data.body.Message : 'Server not reachable.Please try again later'
    }
   } else {
    return { 
      Check_FGlist: response1.data.body ? response1.data.body.FGArray : [],
      Check_FGmessage:response1.data.body ? response1.data.body.Message : 'Server not reachable.Please try again later'
    }
   } 
} else {
  return { 
    Check_FGlist: response.data.body ? response.data.body.FGArray : [],
    Check_FGmessage:response.data.body ? response.data.body.Message : 'Server not reachable.Please try again later'
  }
 } 
} catch (error) {
  return {
    Check_FGmessage:'Server not reachable.Please try again later',
    Check_FGlist: []
  }    
}
})
export const BoxingData = createSlice({
  name: 'BoxingDataList',
  initialState: { 
    params: {},
    statusFlag:0,
    message:'',
    ItemList:[],
    JobFGList:[],
    IronMachineList:[],
    saveFGData:[],
    Check_FGlist: [],
    Check_FGmessage: ''
  },
  reducers: {
    handleStatusFlag: (state, action) => {
      state.statusFlag = action.payload.statusFlag
      state.message = action.payload.message
      state.saveFGData = action.payload.saveFGData
    },
    handleedit: (state, action) => {
      state.editaction = action.payload
    },
    clearstoreData: (state, action) => {
      state.JobFGList = action.payload.JobFGList    
      state.Check_FGlist =  action.payload.Check_FGlist
      state.Check_FGmessage = action.payload.Check_FGmessage
    }
  },
  extraReducers: builder => {
    builder.addCase(onchangeItemList.fulfilled, (state, action) => {
      state.ItemList = action.payload.ItemList
      state.IronMachineList = action.payload.IronMachineList
    }) 
    .addCase(SaveBoxingData.fulfilled, (state, action) => {
      state.statusFlag = action.payload.statusFlag
      state.message = action.payload.message
      state.saveFGData = action.payload.saveFGData
    }) 
    .addCase(GetFGJobIdList.fulfilled, (state, action) => {
      state.JobFGList = action.payload.JobFGList
    }) 
    .addCase(checkFGDispatch.fulfilled, (state, action) => { 
      state.Check_FGlist =  action.payload.Check_FGlist
      state.Check_FGmessage = action.payload.Check_FGmessage
    })
  }
})
export const {
  handleStatusFlag, handleedit, clearstoreData
} = BoxingData.actions
export default BoxingData.reducer
