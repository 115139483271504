// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import { api } from '../../../../utility/constants'


// Design Code search
export const onchangeItemCodeData = createAsyncThunk('StockAdjustmentData/onchangeItemCodeData', async params => {
    try {
        const response = await axios.post(`${api.api_url}/stockadjustment/ChangeAutoItemCodeJwt`, params)
        if (response && response.data && response.data.body && response.data.body.token) {
            params = { jwtToken: response.data.body.token }
            const response1 = await axios.post(`${api.api_url}/stockadjustment/ChangeAutoItemCode`, params)
            if (response1 && response1.data && response1.data.body) {
                return {
                    ListDesign: response1.data.body.DesignList
                }
            } else {
            }
        }
    } catch (error) {
        return {
            ListDesign: []
        }
    }
}
)

// Save 
export const saveStockAdjustment = createAsyncThunk('StockAdjustmentData/saveStockAdjustment', async (params) => {
    try {
        const response = await axios.post(`${api.api_url}/stockadjustment/saveStockAdjustmentJwt`, params)
        if (response && response.data && response.data.body && response.data.body.token) {
            // params['jwtToken'] = response.data.body.token
            params = { jwtToken: response.data.body.token }
            const response1 = await axios.post(`${api.api_url}/stockadjustment/saveStockAdjustment`, params)
            if (response1 && response1.data && response1.data.body) {
                return {
                    statusFlag: response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag : 2,
                    message: response1.data.body.message,
                    saveItemData: response1.data.body.ItemPrint
                }
            } else {
                return {
                    statusFlag: response1.data.body ? response1.data.body.statusFlag : 2,
                    message: response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later',
                    saveItemData: response1.data.body.ItemPrint
                }
            }
        } else {
            return {
                statusFlag: response.data.body ? response.data.body.statusFlag : 2,
                message: response.data.body ? response.data.body.message : 'Server not reachable.Please try again later',
                saveItemData: []
            }
        }
    } catch (error) {
        return {
            statusFlag: 2,
            message: 'Server not reachable.Please try again later',
            saveItemData: []
        }
    }
})


export const getStockAdjustFilterList = createAsyncThunk('StockAdjustmentData/getStockAdjustFilterListJwt', async (params) => {
    try {
        const response = await axios.post(`${api.api_url}/stockadjustment/getStockAdjustFilterListJwt`, params)
        if (response && response.data && response.data.body && response.data.body.token) {
            params = { jwtToken: response.data.body.token }
            const response1 = await axios.post(`${api.api_url}/stockadjustment/getStockAdjustFilterList`, params)
            if (response1 && response1.data && response1.data.body) {
                return {
                    Item_List: response1.data.body && response1.data.body.ItemList ? response1.data.body.ItemList : [],
                    Filter_Message: response1.data.body && response1.data.body.ItemList ? response1.data.body.Message : 'Server not reachable.Please try again later'
                }
            } else {
                return {
                    Item_List: response1.data.body ? response1.data.body.ItemList : [],
                    Filter_Message: response1.data.body ? response1.data.body.Message : 'Server not reachable.Please try again later'
                }
            }
        } else {
            return {
                Item_List: response.data.body ? response.data.body.ItemList : [],
                Filter_Message: response.data.body ? response.data.body.Message : 'Server not reachable.Please try again later'
            }
        }
    } catch (error) {
        return {
            Filter_Message: 'Server not reachable.Please try again later',
            Item_List: []
        }
    }
})

export const getStockAdjustList = createAsyncThunk('StockAdjustmentData/getStockAdjustListJwt', async (params) => {
    try {
        const response = await axios.post(`${api.api_url}/stockadjustment/getStockAdjustListJwt`, params)
        if (response && response.data && response.data.body && response.data.body.token) {
            params = { jwtToken: response.data.body.token }
            const response1 = await axios.post(`${api.api_url}/stockadjustment/getStockAdjustList`, params)
            if (response1 && response1.data && response1.data.body) {
                return {
                    StockAdjustList: response1.data.body && response1.data.body.stock_Adjust_array ? response1.data.body.stock_Adjust_array : [],
                    GRListItemMessage: response1.data.body && response1.data.body.stock_Adjust_array ? response1.data.body.Message : 'Server not reachable.Please try again later',
                    StockAdjustTotal: response1.data.body && response1.data.body.stock_Adjust_total ? response1.data.body.stock_Adjust_total : 0
                    
                }
            } else {
                return {
                    StockAdjustList: response1.data.body ? response1.data.body.stock_Adjust_array : [],
                    GRListItemMessage: response1.data.body ? response1.data.body.Message : 'Server not reachable.Please try again later',
                    StockAdjustTotal: response1.data.body ? response1.data.body.stock_Adjust_total : 0
                }
            }
        } else {
            return {
                StockAdjustList: [],
                GRListItemMessage: 'Server not reachable.Please try again later',
                StockAdjustTotal: 0
            }
        }
    } catch (error) {
        return {
            StockAdjustList: [],
            GRListItemMessage: 'Server not reachable.Please try again later',
            StockAdjustTotal: 0
        }
    }
})

export const getEditStockAdjustList = createAsyncThunk('StockAdjustmentData/getEditStockAdjustJwt', async (params) => {
    try {
        const response = await axios.post(`${api.api_url}/stockadjustment/getEditStockAdjustJwt`, params)
        if (response && response.data && response.data.body && response.data.body.token) {
            params = { jwtToken: response.data.body.token }
            const response1 = await axios.post(`${api.api_url}/stockadjustment/getEditStockAdjust`, params)
            if (response1 && response1.data && response1.data.body) {
                return {
                    EditStockAdjustList: response1.data.body && response1.data.body.EditStockAdjust ? response1.data.body.EditStockAdjust : []
                    
                }
            } else {
                return {
                    EditStockAdjustList: response1.data.body ? response1.data.body.EditStockAdjust : []
                }
            }
        } else {
            return {
                EditStockAdjustList: []
            }
        }
    } catch (error) {
        return {
            EditStockAdjustList: []
        }
    }
})


export const approveStockAdjustment = createAsyncThunk('StockAdjustmentData/approveStockAdjust', async (params) => {
    try {
        const response = await axios.post(`${api.api_url}/stockadjustment/approveStockAdjustJwt`, params)
        if (response && response.data && response.data.body && response.data.body.token) {
            // params['jwtToken'] = response.data.body.token
            params = { jwtToken: response.data.body.token }
            const response1 = await axios.post(`${api.api_url}/stockadjustment/approveStockAdjust`, params)
            if (response1 && response1.data && response1.data.body) {
                return {
                    SA_StatusFlag: response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag : 2,
                    SA_Message: response1.data.body.message
                }
            } else {
                return {
                    SA_StatusFlag: response1.data.body ? response1.data.body.statusFlag : 2,
                    SA_Message: response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
                }
            }
        } else {
            return {
                SA_StatusFlag: response.data.body ? response.data.body.statusFlag : 2,
                SA_Message: response.data.body ? response.data.body.message : 'Server not reachable.Please try again later'
            }
        }
    } catch (error) {
        return {
            SA_StatusFlag: 2,
            SA_Message: 'Server not reachable.Please try again later'
        }
    }
})

export const deleteStockAdjust = createAsyncThunk('StockAdjustmentData/deleteStockAdjustJwt', async (params) => {
    try {
        const response = await axios.post(`${api.api_url}/stockadjustment/deleteStockAdjustJwt`, params)
        if (response && response.data && response.data.body && response.data.body.token) {
            params = { jwtToken: response.data.body.token }
            const response1 = await axios.post(`${api.api_url}/stockadjustment/deleteStockAdjust`, params)
            if (response1 && response1.data && response1.data.body) {
                return {
                    SA_Message: response1.data.body.message,
                    SA_StatusFlag: response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag : 2
                }
            } else {
                return {
                    SA_Message: response1.data.body ? response1.data.body.Message : 'Server not reachable.Please try again later',
                    SA_StatusFlag: response1.data.body ? response1.data.body.statusFlag : 2
                }
            }
        } else {
            return {
                SA_Message: 'Server not reachable.Please try again later',
                SA_StatusFlag: 2
            }
        }
    } catch (error) {
        return {
            SA_Message: 'Server not reachable.Please try again later',
            SA_StatusFlag: 2
        }
    }
})

export const printStockAdjustItemList = createAsyncThunk('StockAdjustmentData/printStockAdjustItemList', async (params) => {
    try {
        const response = await axios.post(`${api.api_url}/stockadjustment/printStockAdjustItemJwt`, params)
        if (response && response.data && response.data.body && response.data.body.token) {
            params = { jwtToken: response.data.body.token }
            const response1 = await axios.post(`${api.api_url}/stockadjustment/printStockAdjustItem`, params)
            if (response1 && response1.data && response1.data.body) {
                return {
                    Print_Stock_Item_List: response1.data.body && response1.data.body.ItemPrint ? response1.data.body.ItemPrint : []
                }
            } else {
                return {
                    Print_Stock_Item_List: response1.data.body ? response1.data.body.ItemPrint : []
                }
            }
        } else {
            return {
                Print_Stock_Item_List: response.data.body ? response.data.body.ItemPrint : []
            }
        }
    } catch (error) {
        return {
            Print_Stock_Item_List: []
        }
    }
})  
export const checkItemCurrentStockAdjust = createAsyncThunk('StockAdjustmentData/checkItemCurrentStockAdjust', async (params) => {
    try {
        const response = await axios.post(`${api.api_url}/stockadjustment/checkItemCurrentStockAdjustJwt`, params)
        if (response && response.data && response.data.body && response.data.body.token) {
            // params['jwtToken'] = response.data.body.token
            params = { jwtToken: response.data.body.token }
            const response1 = await axios.post(`${api.api_url}/stockadjustment/checkItemCurrentStockAdjust`, params)
            if (response1 && response1.data && response1.data.body) {
                return {
                    Item_StatusFlag: response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag : 2,
                    Item_Message: response1.data.body.message
                }
            } else {
                return {
                    Item_StatusFlag: response1.data.body ? response1.data.body.statusFlag : 2,
                    Item_Message: response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
                }
            }
        } else {
            return {
                Item_StatusFlag: response.data.body ? response.data.body.statusFlag : 2,
                Item_Message: response.data.body ? response.data.body.message : 'Server not reachable.Please try again later'
            }
        }
    } catch (error) {
        return {
            Item_StatusFlag: 2,
            Item_Message: 'Server not reachable.Please try again later'
        }
    }
})
export const StockAdjustmentData = createSlice({
    name: 'StockAdjustmentData',
    initialState: {
        params: {},
        statusFlag: 0,
        message: '',
        ListDesign:[],
        StockAdjustList: [],
        EditStockAdjustList: [],
        GRListItemMessage: '',
        StockAdjustTotal: 0,   
        Filter_Message: '',
        Item_List: [],
        SA_Message: '',
        SA_StatusFlag: 0,
        saveItemData: [],
        Print_Stock_Item_List: [],
        Item_Message: '',
        Item_StatusFlag:0
    },
    reducers: {
        handleStatusFlag: (state, action) => {
            state.statusFlag = action.payload.statusFlag
            state.message = action.payload.message
            state.SA_Message = action.payload.SA_Message
            state.SA_StatusFlag = action.payload.SA_StatusFlag
            state.Item_Message = action.payload.Item_Message
            state.Item_StatusFlag = action.payload.Item_StatusFlag
        },
        handleStockAdjustEdit: (state, action) => {
            state.editaction = action.payload
          },
        clearstoreData: (state, action) => {
            state.EditStockAdjustList = action.payload.EditStockAdjustList
            state.StockAdjustList = action.payload.StockAdjustList
            state.GRListItemMessage = action.payload.GRListItemMessage
            state.StockAdjustTotal = action.payload.StockAdjustTotal
            state.Item_List = action.payload.Item_List
            state.Filter_Message = action.payload.Filter_Message
            // state.saveItemData = action.payload.saveItemData    
            state.ListDesign = action.payload.ListDesign        
        },
        clearPrintstoreData: (state, action) => {
            state.saveItemData = action.payload.saveItemData
            state.Print_Stock_Item_List = action.payload.Print_Stock_Item_List
        }
    },
    extraReducers: builder => {
        builder.addCase(onchangeItemCodeData.fulfilled, (state, action) => {            
            state.ListDesign = action.payload.ListDesign
        }).addCase(saveStockAdjustment.fulfilled, (state, action) => {
            state.statusFlag = action.payload.statusFlag
            state.message = action.payload.message
            state.saveItemData = action.payload.saveItemData
        }).addCase(getStockAdjustFilterList.fulfilled, (state, action) => {
            state.Item_List = action.payload.Item_List
            state.Filter_Message = action.payload.Filter_Message
        }).addCase(getStockAdjustList.fulfilled, (state, action) => {
            state.StockAdjustList = action.payload.StockAdjustList
            state.GRListItemMessage = action.payload.GRListItemMessage
            state.StockAdjustTotal = action.payload.StockAdjustTotal
        }).addCase(getEditStockAdjustList.fulfilled, (state, action) => {
            state.EditStockAdjustList = action.payload.EditStockAdjustList
        }).addCase(deleteStockAdjust.fulfilled, (state, action) => {
            state.SA_Message = action.payload.SA_Message
            state.SA_StatusFlag = action.payload.SA_StatusFlag
        }).addCase(approveStockAdjustment.fulfilled, (state, action) => {
            state.SA_Message = action.payload.SA_Message
            state.SA_StatusFlag = action.payload.SA_StatusFlag
        }).addCase(printStockAdjustItemList.fulfilled, (state, action) => {
            state.Print_Stock_Item_List = action.payload.Print_Stock_Item_List
        }).addCase(checkItemCurrentStockAdjust.fulfilled, (state, action) => {
            state.Item_Message = action.payload.Item_Message
            state.Item_StatusFlag = action.payload.Item_StatusFlag
        })        
    }
})
export const {
    handleStatusFlag, clearstoreData, handleStockAdjustEdit, clearPrintstoreData
} = StockAdjustmentData.actions
export default StockAdjustmentData.reducer
