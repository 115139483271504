// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import { api } from '../../../../utility/constants'


//  GET Common LIST
export const commonData = createAsyncThunk('JobCard/commonData', async (params) => {
  const response = await axios.post(`${api.api_url}/common/fetchcommonDataJwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/common/fetchcommonData`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      params,
      DesignArray: response1.data.body.DesignArray,
      MachineArray: response1.data.body.MachineArray,
      ColorArray: response1.data.body.ColorArray,
      EmployeeArray: response1.data.body.EmployeeArray,
      ItemArray: response1.data.body.ItemArray,
      JobTypeArray:response1.data.body.JobTypeArray,
      alljobtypeArray:response1.data.body.alljobtypeArray,
      allcolorArray:response1.data.body.allcolorArray,
      allitemgroupArray:response1.data.body.allitemgroupArray,
      alldesignArray:response1.data.body.alldesignArray
    }
   } else {
   } 
}
})

// item search
export const onchangeitemData = createAsyncThunk('JobCard/onchangeitemData', async params => {
    const response = await axios.post(`${api.api_url}/common/onchangeitemDataJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = {jwtToken:response.data.body.token}
      const response1 = await axios.post(`${api.api_url}/common/onchangeitemData`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          ListItem: response1.data.body.ItemList
        }
      } else {
      }
    }
  }
)

// Machine Wise Employee search
export const onchangemachineemployeeData = createAsyncThunk('JobCard/onchangemachineemployeeData', async params => {
  const response = await axios.post(`${api.api_url}/common/onchangeMachineEmployeeDataJwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = {jwtToken:response.data.body.token}
    const response1 = await axios.post(`${api.api_url}/common/onchangeMachineEmployeeData`, params)
    if (response1 && response1.data && response1.data.body) {
      return {
        MachineEmployeeList: response1.data.body.MachineEmployeeList
      }
    } else {
    }
  }
}
)

// Design search
export const onchangedesignData = createAsyncThunk('JobCard/onchangedesignData', async params => {
  const response = await axios.post(`${api.api_url}/common/onchangeDesignDataJwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = {jwtToken:response.data.body.token}
    const response1 = await axios.post(`${api.api_url}/common/onchangeDesignData`, params)
    if (response1 && response1.data && response1.data.body) {
      return {
        ListDesign: response1.data.body.DesignList
      }
    } else {
    }
  }
}
)

// Machine search
export const onchangemachineData = createAsyncThunk('JobCard/onchangemachineData', async params => {
  const response = await axios.post(`${api.api_url}/common/onchangeMachineDataJwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = {jwtToken:response.data.body.token}
    const response1 = await axios.post(`${api.api_url}/common/onchangeMachineData`, params)
    if (response1 && response1.data && response1.data.body) {
      return {
        ListMachine: response1.data.body.MachineList
      }
    } else {
    }
  }
}
)

// Size search
export const onchangesizeData = createAsyncThunk('JobCard/onchangesizeData', async params => {
  const response = await axios.post(`${api.api_url}/common/onchangeSizeDataJwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = {jwtToken:response.data.body.token}
    const response1 = await axios.post(`${api.api_url}/common/onchangeSizeData`, params)
    if (response1 && response1.data && response1.data.body) {
      return {
        ListSize: response1.data.body.SizeList
      }
    } else {
    }
  }
}
) 
// Size search
export const onChangeJobCard = createAsyncThunk('JobCard/onChangeJobCard', async params => {
  const response = await axios.post(`${api.api_url}/common/onChangeJobCardJwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = {jwtToken:response.data.body.token}
    const response1 = await axios.post(`${api.api_url}/common/onChangeJobCard`, params)
    if (response1 && response1.data && response1.data.body) {
      return {
        designList: response1.data.body.DesignList
      }
    } else {
    }
  }
}
) 

// Employee search
export const onchangeemployeeData = createAsyncThunk('JobCard/onchangeemployeeData', async params => {
  const response = await axios.post(`${api.api_url}/common/onchangeEmployeeDataJwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = {jwtToken:response.data.body.token}
    const response1 = await axios.post(`${api.api_url}/common/onchangeEmployeeData`, params)
    if (response1 && response1.data && response1.data.body) {
      return {
        ListEmployee: response1.data.body.EmployeeList
      }
    } else {
    }
  }
}
)

// JobCard LIST
export const JobCardlist = createAsyncThunk('JobCard/JobCartlist', async params => {
  try {
  const response = await axios.post(`${api.api_url}/production/jobcard/jobcardListjwt`, params)
   if (response && response.data && response.data.body && response.data.body.token) {
   params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/production/jobcard/jobcardList`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      params,
      JobCardlist: response1.data.body && response1.data.body.JobcardArray ? response1.data.body.JobcardArray : [],
      CompanyList: response1.data.body && response1.data.body.CompanyArray ?  response1.data.body.CompanyArray : [],
      TotalJobCard: response1.data.body && response1.data.body.TotalData ?  response1.data.body.TotalData : {},
      Total_Jobs: response1.data.body && response1.data.body.Total_Jobs ? response1.data.body.Total_Jobs : 0
    }
   } else {
    return {
      params,
      JobCardlist: response1.data.body ? response1.data.body.JobcardArray : [],
      CompanyList: response1.data.body ? response1.data.body.CompanyArray : [],
      TotalJobCard: response1.data.body ? response1.data.body.TotalData : {},
      Total_Jobs: response1.data.body ? response1.data.body.Total_Jobs : 0
    }
   } 
  } else {
    return {
      params,
      JobCardlist: response.data.body ? response.data.body.JobcardArray : [],
      CompanyList: response.data.body ? response.data.body.CompanyArray : [],
      TotalJobCard: response.data.body ? response.data.body.TotalData : {},
      Total_Jobs: response.data.body ? response.data.body.Total_Jobs : 0
    }
   }
  } catch (error) {
    return {
      params,
      JobCardlist: [],
      CompanyList: [],
      TotalJobCard: {},
      Total_Jobs : 0
    }    
  }
})

//saveData Api
export const saveData = createAsyncThunk('JobCard/saveData', async params => {
  try {
  const response = await axios.post(`${api.api_url}/production/jobcard/saveJobcardjwt`, params)
   if (response && response.data && response.data.body && response.data.body.token) {
   params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/production/jobcard/saveJobcard`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      statusFlag:response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag : 2,
      message: response1.data.body.message,
      saveJobData: response1.data.body.resultData
    }
   } else {
    return {
      statusFlag:response1.data.body ? response1.data.body.statusFlag : 2,
      message: response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later',
      saveJobData: response1.data.body ? response1.data.body.resultData : {}
    }
   } 
  } else {
    return {
      statusFlag:response.data.body ? response.data.body.statusFlag : 2,
      message: response.data.body ? response.data.body.message : 'Server not reachable.Please try again later',
      saveJobData: response.data.body ? response.data.body.resultData : {}
    }
   }
  } catch (error) {
    return {
      statusFlag:2,
      message:'Server not reachable.Please try again later',
      saveJobData : {}
    }    
  }
})


//saveData Rate
export const saveRateData = createAsyncThunk('JobCard/saveRateData', async params => {
  try {
  const response = await axios.post(`${api.api_url}/production/jobcard/updateRateJobcardjwt`, params)
   if (response && response.data && response.data.body && response.data.body.token) {
   params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/production/jobcard/updateRateJobcard`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      rateStatusFlag:response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag : 2,
      rateMessage: response1.data.body.message 
    }
   } else {
    return {
      rateStatusFlag: response1.data.body ? response1.data.body.statusFlag : 2,
      rateMessage: response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
    }
   }
  } else {
    return {
      rateStatusFlag: response.data.body ? response.data.body.statusFlag : 2,
      rateMessage: response.data.body ? response.data.body.message : 'Server not reachable.Please try again later'
    }
   }
  } catch (error) {
    return {
      rateStatusFlag:2,
      rateMessage:'Server not reachable.Please try again later'      
    }    
  }
})

//deleteData Api
export const deleteData = createAsyncThunk('JobCard/deleteData', async params => { 
  const response = await axios.post(`${api.api_url}/production/jobcard/deleteJobcardjwt`, params)  
  if (response && response.data && response.data.body && response.data.body.token) { 
    params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/production/jobcard/deleteJobcard`, params)
   if (response1 && response1.data && response1.data.body) { 
    return { 
      statusFlag:response1.data.body.statusFlag, 
      message:response1.data.body.message
    }
   }
  } else { 
    return {  
      statusFlag:response.data.statusFlag,
      message:response.data.message
    }
   }
})
// Edit JobCard LIST
export const editData = createAsyncThunk('JobCard/editData', async (params) => {
  const response = await axios.post(`${api.api_url}/production/jobcard/editJobcardjwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = {jwtToken:response.data.body.token}
    const response1 = await axios.post(`${api.api_url}/production/jobcard/editJobcard`, params)
    if (response1 && response1.data && response1.data.body) {
    return {
      params,
      EditJobcardArray: response1.data.body.EditJobcardArray
    }
   } else {
   } 
}
})

// Generate Jobno
export const generateJobno = createAsyncThunk('JobCard/generateJobno', async (params) => {
  const response = await axios.post(`${api.api_url}/production/jobcard/generateJobnojwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = {jwtToken:response.data.body.token}
    const response1 = await axios.post(`${api.api_url}/production/jobcard/generateJobno`, params)
    if (response1 && response1.data && response1.data.body) {
    return {
      JobNo: response1.data.body.JobNo
    }
   } else {
   } 
}
})

//saveTransferData Api
export const saveTransferData = createAsyncThunk('JobCard/saveTransferData', async params => {
  try {
  const response = await axios.post(`${api.api_url}/production/jobcard/saveTransferJobJwt`, params)
   if (response && response.data && response.data.body && response.data.body.token) {
   params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/production/jobcard/saveTransferJob`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      statusFlag:response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag : 2,
      message:response1.data.body.message
    }
   } else {
    return {
      statusFlag:response1.data.body ? response1.data.body.statusFlag : 2,
      message:response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
    }
   } 
  } else {
    return {
      statusFlag:response.data.body ? response.data.body.statusFlag : 2,
      message:response.data.body ? response.data.body.message : 'Server not reachable.Please try again later'
    }
   }
  } catch (error) {
    return {
      statusFlag:2,
      message:'Server not reachable.Please try again later'
    }    
  }
})

// View JobCard LIST
export const viewData = createAsyncThunk('JobCard/viewData', async (params) => {
  const response = await axios.post(`${api.api_url}/production/jobcard/viewTransferJobcardjwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = {jwtToken:response.data.body.token}
    const response1 = await axios.post(`${api.api_url}/production/jobcard/viewTransferJobcard`, params)
    if (response1 && response1.data && response1.data.body) {
    return {
      params,
      ViewJobcardArray: response1.data.body.ViewJobcardArray
    }
   } else {
   } 
}
})

export const JobCardSlice = createSlice({
  name: 'JobCard',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    JobCardlist:[],
    Total_Jobs: 0,
    CompanyList: [],
    TotalJobCard:{},
    statuslist:[],
    statusFlag: 0,
    saveJobData: {},
    statuscode:0,
    DesignArray:[],
    MachineArray:[],
    ColorArray:[],
    EmployeeArray:[],
    ItemArray:[],
    ListItem:[],
    ListDesign:[],
    ListMachine:[],
    JobTypeArray:[],
    ListEmployee:[],
    allcolorArray:[],
    alljobtypeArray:[],
    allitemgroupArray:[],
    alldesignArray:[],
    EditJobcardArray:[],
    MachineEmployeeList:[],
    ViewJobcardArray: [],
    designList: [],
    JobNo: '',
    rateStatusFlag: 0,
    rateMessage: '',
    jobId: ''
  },
  reducers: {
    handleStatusFlag: (state, action) => {
      state.statusFlag = action.payload.statusFlag
    },
    handleRateStatusFlag: (state, action) => {
      state.rateStatusFlag = action.payload.rateStatusFlag
    },
    clearstoreData: (state, action) => {
      state.EditJobcardArray = action.payload.EditJobcardArray
      state.DesignArray = action.payload.DesignArray
      state.MachineArray = action.payload.MachineArray
      state.EmployeeArray = action.payload.EmployeeArray
      state.ListSize = action.payload.ListSize
      state.MachineEmployeeList = action.payload.MachineEmployeeList
      state.ListMachine = action.payload.ListMachine
      state.ViewJobcardArray = action.payload.ViewJobcardArray
      state.designList = action.payload.designList
    },
    handlepage: (state, action) => {
      state.jobId = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(saveData.fulfilled, (state, action) => {
      state.statusFlag = action.payload.statusFlag
      state.message = action.payload.message
      state.saveJobData = action.payload.saveJobData
    }) 
    .addCase(saveRateData.fulfilled, (state, action) => {
      state.rateStatusFlag = action.payload.rateStatusFlag
      state.rateMessage = action.payload.rateMessage
    })
    .addCase(saveTransferData.fulfilled, (state, action) => {
      state.statusFlag = action.payload.statusFlag
      state.message = action.payload.message
    })
    .addCase(commonData.fulfilled, (state, action) => {
      state.DesignArray = action.payload.DesignArray
      state.MachineArray = action.payload.MachineArray
      state.ColorArray = action.payload.ColorArray
      state.EmployeeArray = action.payload.EmployeeArray
      state.ItemArray = action.payload.ItemArray
      state.JobTypeArray = action.payload.JobTypeArray
      state.allcolorArray = action.payload.allcolorArray
      state.alljobtypeArray = action.payload.alljobtypeArray
      state.allitemgroupArray = action.payload.allitemgroupArray
      state.alldesignArray = action.payload.alldesignArray
      state.params = action.payload.params
    })
    .addCase(deleteData.fulfilled, (state, action) => {
      state.message = action.payload.message
     state.statusFlag = action.payload.statusFlag
    })
    .addCase(JobCardlist.fulfilled, (state, action) => {
      state.JobCardlist = action.payload.JobCardlist
      state.Total_Jobs = action.payload.Total_Jobs
      state.CompanyList = action.payload.CompanyList
      state.TotalJobCard = action.payload.TotalJobCard
      state.params = action.payload.params
    })
    .addCase(onchangeitemData.fulfilled, (state, action) => {
      state.ListItem = action.payload.ListItem
    })
    .addCase(onChangeJobCard.fulfilled, (state, action) => {
      state.designList = action.payload.designList
    })
    .addCase(onchangemachineemployeeData.fulfilled, (state, action) => {
      state.MachineEmployeeList = action.payload.MachineEmployeeList
    })
    .addCase(onchangedesignData.fulfilled, (state, action) => {
      state.ListDesign = action.payload.ListDesign
    })
    .addCase(onchangemachineData.fulfilled, (state, action) => {
      state.ListMachine = action.payload.ListMachine
    })
    .addCase(onchangesizeData.fulfilled, (state, action) => {
      state.ListSize = action.payload.ListSize
    })
    .addCase(onchangeemployeeData.fulfilled, (state, action) => {
      state.ListEmployee = action.payload.ListEmployee
    })
    .addCase(editData.fulfilled, (state, action) => {
      state.EditJobcardArray = action.payload.EditJobcardArray
      state.params = action.payload.params
    })
    .addCase(generateJobno.fulfilled, (state, action) => {
      state.JobNo = action.payload.JobNo
    })
    .addCase(viewData.fulfilled, (state, action) => {
      state.ViewJobcardArray = action.payload.ViewJobcardArray
      state.params = action.payload.params
    })
  }
})
export const {
  handleStatusFlag, clearstoreData, handleRateStatusFlag, handlepage
} = JobCardSlice.actions
export default JobCardSlice.reducer
