// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import { api } from '../../../utility/constants'

// Dashboard LIST
export const Dashboardlist = createAsyncThunk('Dashboard/Dashboardlist', async (params) => {
  const response = await axios.post(`${api.api_url}/report/dashboardCountListjwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = { jwtToken: response.data.body.token }
    const response1 = await axios.post(`${api.api_url}/report/dashboardCountList`, params)
    if (response1 && response1.data && response1.data.body) {
      return {
        params,
        TotalJob: response1.data.body.TotalJob,
        PendingJob: response1.data.body.PendingJob,
        CompletedJob: response1.data.body.CompletedJob,
        TransferJob: response1.data.body.TransferJob,
        TotalJobSet: response1.data.body.TotalJobSet,
        PendingJobSet: response1.data.body.PendingJobSet,
        CompletedJobSet: response1.data.body.CompletedJobSet,
        TransferJobSet: response1.data.body.TransferJobSet,
        TotalJobPiece: response1.data.body.TotalJobPiece,
        PendingJobPiece: response1.data.body.PendingJobPiece,
        CompletedJobPiece: response1.data.body.CompletedJobPiece,
        TransferJobPiece: response1.data.body.TransferJobPiece
      }
    } else {
      return {
        params,
        TotalJob: 0,
        PendingJob: 0,
        CompletedJob: 0,
        TransferJob: 0,
        TotalJobSet: 0,
        PendingJobSet: 0,
        CompletedJobSet: 0,
        TransferJobSet: 0,
        TotalJobPiece: 0,
        PendingJobPiece: 0,
        CompletedJobPiece: 0,
        TransferJobPiece: 0
      }
    }
  }
})

export const DashBoardListData = createAsyncThunk('Dashboard/DashBoardListData', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/report/dashboardListJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params['jwtToken'] = response.data.body.token
      // params = {jwtToken:response.data.body.token}
      const response1 = await axios.post(`${api.api_url}/report/dashboardList`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          TotalOrder: response1.data.body.TotalOrder,
          TotalDispatch: response1.data.body.TotalDispatch,
          TotalCountDispatch: response1.data.body.TotalCountDispatch,
          TotalFG: response1.data.body.TotalFG,
          Total_Job: response1.data.body.TotalJob,
          TotalOrderCategorywise: response1.data.body.TotalOrderCategorywise,
          TotalCurrentStockList: response1.data.body.TotalCurrentStockList,
          JobCardList: response1.data.body.JobCardList,
          JobCardLeastTooltip: response1.data.body.JobCardLeastTooltip,
          JobCardTopTooltip: response1.data.body.JobCardTopTooltip,
          TotalJobCardEmployeewiseTop: response1.data.body.TotalJobCardEmployeewiseTop,
          TotalJobCardEmployeewiseLeast: response1.data.body.TotalJobCardEmployeewiseLeast,
          JobCardCatagoriesList: response1.data.body.JobCardCatagoriesList,
          ReadyToDispatchCategory: response1.data.body.ReadyToDispatchCategory,
          TotalReadyDispatch: response1.data.body.TotalReadyDispatch,
          TotalDispatchCategorywise: response1.data.body.TotalDispatchCategorywise,
          TotalCountFG: response1.data.body.TotalCountFG,
          TotalFgCategorywise: response1.data.body.TotalFgCategorywise,
          TotalJobCardCategorywise: response1.data.body.TotalJobCardCategorywise,
          OverallTotalOrder: response1.data.body.OverallTotalOrder,
          TotalCancelOrder: response1.data.body.TotalCancelOrder,
          TotalCancelDispatch: response1.data.body.TotalCancelDispatch,
          TotalCancelledDispatch: response1.data.body.TotalCancelledDispatch,
          TotalCancellledSetOrder: response1.data.body.Total_Cancellled_Set_Order,
          FGWidget: response1.data.body.FGWidget,
          TopFiveCustomerwiseOrder: response1.data.body.TopFiveCustomerwiseOrder,
          LeastFiveCustomerwiseOrder: response1.data.body.LeastFiveCustomerwiseOrder,
          TopFiveItemWiseOrder: response1.data.body.TopFiveItemWiseOrder,
          LeastFiveItemWiseOrder: response1.data.body.LeastFiveItemWiseOrder,
          TopFiveAgentWiseOrder: response1.data.body.TopFiveAgentWiseOrder,
          LeastFiveAgentWiseOrder: response1.data.body.LeastFiveAgentWiseOrder
        }
      } else {
        return {
          TotalOrder: [],
          TotalDispatch: [],
          TotalCountDispatch: 0,
          TotalFG: [],
          Total_Job: [],
          TotalOrderCategorywise: [],
          TotalCurrentStockList: [],
          JobCardList:[],
          JobCardLeastTooltip:[],
          JobCardTopTooltip:[],
          TotalJobCardEmployeewiseTop: [],
          TotalJobCardEmployeewiseLeast: [],
          JobCardCatagoriesList:[],
          ReadyToDispatchCategory:[],
          TotalReadyDispatch: [],
          TotalDispatchCategorywise: [],
          TotalFgCategorywise: [],
          TotalCountFG: 0,
          TotalJobCardCategorywise: [],
          OverallTotalOrder: 0,
          TotalCancelOrder: 0,
          TotalCancelledDispatch: [],
          TotalCancelDispatch: 0,
          TotalCancellledSetOrder: [],
          FGWidget: [],
          TopFiveCustomerwiseOrder: [],
          LeastFiveCustomerwiseOrder: [],
          TopFiveItemWiseOrder: [],
          LeastFiveItemWiseOrder: [],
          TopFiveAgentWiseOrder: [],
          LeastFiveAgentWiseOrder: []
        }
      }
    } else {
      return {
        TotalOrder: [],
        TotalDispatch: [],
        TotalCountDispatch: 0,
        TotalFG: [],
        Total_Job: [],
        TotalReadyDispatch: [],
        TotalOrderCategorywise: [],
        TotalCurrentStockList: [],
        JobCardList: [],
        JobCardLeastTooltip: [],
        JobCardTopTooltip: [],
        TotalJobCardEmployeewiseTop: [],
        TotalJobCardEmployeewiseLeast: [],
        JobCardCatagoriesList: [],
        ReadyToDispatchCategory: [],
        TotalDispatchCategorywise: [],
        TotalFgCategorywise: [],
        TotalCountFG: 0,
        TotalJobCardCategorywise: [],
        OverallTotalOrder: 0,
        TotalCancellledSetOrder: [],
        FGWidget: [],
        TotalCancelOrder: 0,
        TotalCancelledDispatch: [],
        TotalCancelDispatch: 0,
        TopFiveCustomerwiseOrder: [],
        LeastFiveCustomerwiseOrder: [],
        TopFiveItemWiseOrder: [],
        LeastFiveItemWiseOrder: [],
        TopFiveAgentWiseOrder: [],
        LeastFiveAgentWiseOrder: []
      }
    }
  } catch (error) {
    return {
      TotalOrder: [],
      TotalDispatch: [],
      TotalCountDispatch: 0,
      TotalFG: [],
      Total_Job: [],
      TotalOrderCategorywise: [],
      TotalCurrentStockList: [],
      JobCardList: [],
      JobCardLeastTooltip:[],
      JobCardTopTooltip: [],
      TotalJobCardEmployeewiseTop: [],
      TotalJobCardEmployeewiseLeast: [],
      JobCardCatagoriesList: [],
      ReadyToDispatchCategory: [],
      TotalReadyDispatch: [],
      TotalDispatchCategorywise: [],
      TotalFgCategorywise: [],
      TotalCountFG: 0,
      TotalJobCardCategorywise: [],
      OverallTotalOrder: 0,
      TotalCancelOrder: 0,
      TotalCancelledDispatch: [],
      TotalCancelDispatch: 0,
      TotalCancellledSetOrder: [],
      FGWidget: [],
      TopFiveCustomerwiseOrder: [],
      LeastFiveCustomerwiseOrder: [],
      TopFiveItemWiseOrder: [],
      LeastFiveItemWiseOrder: [],
      TopFiveAgentWiseOrder: [],
      LeastFiveAgentWiseOrder: []
    }
  }
})

// Pending Job LIST
export const PendingJobList = createAsyncThunk('Dashboard/PendingJobList', async (params) => {
  const response = await axios.post(`${api.api_url}/report/pendingListjwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = { jwtToken: response.data.body.token }
    const response1 = await axios.post(`${api.api_url}/report/pendingList`, params)
    if (response1 && response1.data && response1.data.body) {
      return {
        params,
        PendingJobArray: response1.data.body.PendingJobList
      }
    } else {
      return {
        params,
        PendingJobArray: []
      }
    }
  }
})

export const DashboardSlice = createSlice({
  name: 'Dashboard',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    statuslist: [],
    PendingJobArray: [],
    statusFlag: 0,
    statuscode: 0,
    TotalJob: 0,
    PendingJob: 0,
    CompletedJob: 0,
    TransferJob: 0,
    TotalJobSet: 0,
    PendingJobSet: 0,
    CompletedJobSet: 0,
    TransferJobSet: 0,
    TotalJobPiece: 0,
    PendingJobPiece: 0,
    CompletedJobPiece: 0,
    TransferJobPiece: 0,
    TotalOrder: [],
    TotalDispatch: [],
    TotalCountDispatch: 0,
    TotalFG: [],
    Total_Job: [],
    TotalOrderCategorywise: [],
    TotalCurrentStockList: [],
    JobCardList: [],
    JobCardLeastTooltip: [],
    JobCardTopTooltip: [],
    TotalJobCardEmployeewiseTop: [],
    TotalJobCardEmployeewiseLeast: [],
    JobCardCatagoriesList: [],
    ReadyToDispatchCategory: [],
    TotalReadyDispatch: [],
    TotalDispatchCategorywise: [],
    TotalFgCategorywise: [],
    TotalCountFG: 0,
    TotalJobCardCategorywise: [],
    OverallTotalOrder: 0,
    TotalCancelOrder: 0,
    TotalCancelledDispatch: [],
    TotalCancelDispatch: 0,
    TotalCancellledSetOrder: [],
    FGWidget: [],
    TopFiveCustomerwiseOrder: [],
    LeastFiveCustomerwiseOrder: [],
    TopFiveItemWiseOrder: [],
    LeastFiveItemWiseOrder: [],
    TopFiveAgentWiseOrder: [],
    LeastFiveAgentWiseOrder: []
  },
  reducers: {
    handleStatusFlag: (state, action) => {
      state.statusFlag = action.payload.statusFlag
    },
    handleedit: (state, action) => {
      state.editaction = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(Dashboardlist.fulfilled, (state, action) => {
      state.params = action.payload.params
      state.TotalJob = action.payload.TotalJob
      state.PendingJob = action.payload.PendingJob
      state.CompletedJob = action.payload.CompletedJob
      state.TransferJob = action.payload.TransferJob
      state.TotalJobSet = action.payload.TotalJobSet
      state.PendingJobSet = action.payload.PendingJobSet
      state.CompletedJobSet = action.payload.CompletedJobSet
      state.TransferJobSet = action.payload.TransferJobSet
      state.TotalJobPiece = action.payload.TotalJobPiece
      state.PendingJobPiece = action.payload.PendingJobPiece
      state.CompletedJobPiece = action.payload.CompletedJobPiece
      state.TransferJobPiece = action.payload.TransferJobPiece
    })
      .addCase(PendingJobList.fulfilled, (state, action) => {
        state.params = action.payload.params
        state.PendingJobArray = action.payload.PendingJobArray
      })
      .addCase(DashBoardListData.fulfilled, (state, action) => {
        state.TotalDispatch = action.payload.TotalDispatch
        state.TotalCountDispatch = action.payload.TotalCountDispatch
        state.TotalOrder = action.payload.TotalOrder
        state.TotalFG = action.payload.TotalFG
        state.Total_Job = action.payload.Total_Job
        state.TotalCurrentStockList = action.payload.TotalCurrentStockList
        state.JobCardList = action.payload.JobCardList
        state.JobCardLeastTooltip = action.payload.JobCardLeastTooltip
        state.JobCardTopTooltip = action.payload.JobCardTopTooltip
        state.TotalJobCardEmployeewiseTop = action.payload.TotalJobCardEmployeewiseTop
        state.TotalJobCardEmployeewiseLeast = action.payload.TotalJobCardEmployeewiseLeast
        state.JobCardCatagoriesList = action.payload.JobCardCatagoriesList
        state.TotalOrderCategorywise = action.payload.TotalOrderCategorywise
        state.ReadyToDispatchCategory = action.payload.ReadyToDispatchCategory
        state.TotalReadyDispatch = action.payload.TotalReadyDispatch
        state.TotalDispatchCategorywise = action.payload.TotalDispatchCategorywise
        state.TotalFgCategorywise = action.payload.TotalFgCategorywise
        state.TotalCountFG = action.payload.TotalCountFG
        state.TotalJobCardCategorywise = action.payload.TotalJobCardCategorywise
        state.OverallTotalOrder = action.payload.OverallTotalOrder
        state.TotalCancelOrder = action.payload.TotalCancelOrder
        state.TotalCancelDispatch = action.payload.TotalCancelDispatch
        state.TotalCancelledDispatch = action.payload.TotalCancelledDispatch
        state.TotalCancellledSetOrder = action.payload.TotalCancellledSetOrder
        state.FGWidget = action.payload.FGWidget
        // state.DispatchWidgetList = action.payload.DispatchWidgetList
        state.TopFiveCustomerwiseOrder = action.payload.TopFiveCustomerwiseOrder
        state.LeastFiveCustomerwiseOrder = action.payload.LeastFiveCustomerwiseOrder
        state.TopFiveItemWiseOrder = action.payload.TopFiveItemWiseOrder
        state.LeastFiveItemWiseOrder = action.payload.LeastFiveItemWiseOrder 
        state.TopFiveAgentWiseOrder = action.payload.TopFiveAgentWiseOrder
        state.LeastFiveAgentWiseOrder = action.payload.LeastFiveAgentWiseOrder
      })
  }
})
export const {
  handleStatusFlag, handleedit
} = DashboardSlice.actions
export default DashboardSlice.reducer
