// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import { api } from '../../../../../utility/constants'


// CustomerWiseOrder LIST
export const CustomerWiseOrderReportlist = createAsyncThunk('CustomerWiseOrder/CustomerWiseOrderReportlist', async (params) => {
    const response = await axios.post(`${api.api_url}/orderreport/customerWiseOrderListjwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = {jwtToken:response.data.body.token}
      const response1 = await axios.post(`${api.api_url}/orderreport/customerWiseOrderList`, params)
      if (response1 && response1.data && response1.data.body) { 
      return {
        params,
        CustomerWiseOrderlist: response1.data.body.OrderTakinglist,
        CustomerWiseTotalCount: response1.data.body.OrderTotalCount,
        TotalQty: response1.data.body.TotalQty,
        OrderWidget: response1.data.body.OrderWidget
      }
     } else {
     } 
  }
  })

  // Excel CustomerWiseOrder LIST
export const ExcelCustomerWiseOrderReportlist = createAsyncThunk('CustomerWiseOrder/ExcelCustomerWiseOrderReportlist', async (params) => {
  const response = await axios.post(`${api.api_url}/orderreport/customerWiseOrderListjwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = {jwtToken:response.data.body.token}
    const response1 = await axios.post(`${api.api_url}/orderreport/customerWiseOrderList`, params)
    if (response1 && response1.data && response1.data.body) { 
    return {
      params,
      ExcelOrderReportlist: response1.data.body.OrderTakinglist,
      CustomerWiseTotalCount: response1.data.body.OrderTotalCount,
      company_details: response1.data.body.company_details,
      item_Result: response1.data.body.item_Result,
      ExcelOrderWidget: response1.data.body.OrderWidget
    }
   } else {
   } 
}
})

//Print Order Slip
export const printOrderSlip = createAsyncThunk('OrderTracking/printOrderSlip', async params => {
  const response = await axios.post(`${api.api_url}/orderManagement/orderTaking/printOrderSlipjwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = {jwtToken:response.data.body.token}
    const response1 = await axios.post(`${api.api_url}/orderManagement/orderTaking/printOrderSlip`, params)
    if (response1 && response1.data && response1.data.body) {
      return {
        OrderSlipList: response1.data.body.OrderSlip,
        CompanyList: response1.data.body.CompanyArray,
        Order_CustomerList: response1.data.body.CustomerArray,
        ItemLists: response1.data.body.ItemLists
      }
    } else {
    }
  }
}
)
  //  GET Common LIST
  export const commonData = createAsyncThunk('CustomerOrder/commonData', async (params) => {
    const response = await axios.post(`${api.api_url}/common/fetchcommonDataJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = {jwtToken:response.data.body.token}
     const response1 = await axios.post(`${api.api_url}/common/fetchcommonData`, params)
     if (response1 && response1.data && response1.data.body) {
      return {
        params,
        customerList: response1.data.body.customerList,
        agentList : response1.data.body.agentdata
      }
     } else {
     } 
  }
  })

export const CustomerWiseOrderReportSlice = createSlice({
  name: 'CustomerWiseOrder',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    CustomerWiseOrderlist: [],
    CustomerWiseTotalCount:0,
    ExcelOrderReportlist:[],
    ExcelOrderWidget: [],
    statuslist:[],
    statusFlag:0,
    statuscode: 0,
    TotalQty: [],
    OrderWidget: [],
    company_details: [],
    OrderSlipList :[],
    CompanyList :[],
    customerList:[],
    Order_CustomerList :[],
    item_Result: [],
    ItemLists: [],
    agentList: []
  },
  reducers: {
    handleStatusFlag: (state, action) => {
      state.statusFlag = action.payload.statusFlag
    },
    clearstoreData: (state, action) => {
      state.ExcelOrderReportlist = action.payload.ExcelOrderReportlist
      state.OrderSlipList = action.payload.OrderSlipList
      state.ItemLists = action.payload.ItemLists
    }
  },
  extraReducers: builder => {
    builder.addCase(CustomerWiseOrderReportlist.fulfilled, (state, action) => {
      state.CustomerWiseOrderlist = action.payload.CustomerWiseOrderlist
      state.CustomerWiseTotalCount = action.payload.CustomerWiseTotalCount
      state.params = action.payload.params
      state.TotalQty = action.payload.TotalQty 
      state.OrderWidget = action.payload.OrderWidget
    })
    .addCase(ExcelCustomerWiseOrderReportlist.fulfilled, (state, action) => {
      state.ExcelOrderReportlist = action.payload.ExcelOrderReportlist 
      state.company_details = action.payload.company_details
      state.params = action.payload.params
      state.item_Result = action.payload.item_Result
      state.ExcelOrderWidget = action.payload.ExcelOrderWidget
    })
    .addCase(commonData.fulfilled, (state, action) => {
      state.customerList = action.payload.customerList
      state.params = action.payload.params
      state.agentList =  action.payload.agentList
    })
    .addCase(printOrderSlip.fulfilled, (state, action) => {
      state.OrderSlipList = action.payload.OrderSlipList
      state.CompanyList = action.payload.CompanyList
      state.Order_CustomerList = action.payload.Order_CustomerList
      state.ItemLists = action.payload.ItemLists
    })
  }
})
export const {
  handleStatusFlag, clearstoreData
} = CustomerWiseOrderReportSlice.actions
export default CustomerWiseOrderReportSlice.reducer